export const INTERACTION_CATEGORY = 'interaction';
export const INTERACTION_RECEIVED_ACTION = 'Interaction Received';

export const TEAM_SCREAM_ACCEPT = 'Team Scream Accepted';
export const TEAM_SCREAM_DECLINE = 'Team Scream Declined';
export const TEAM_SCREAM_NON_PARTICIPATION = 'Team Scream Non Participation';

export const JOIN_GAME_CATEGORY = 'join_game';
export const JOIN_GAME_ACTION = 'Join game from home game card';
export const JOIN_GAME_LABEL = 'Join game';
export const EXIT_GAME_CATEGORY = 'exit_game';
export const EXIT_GAME_ACTION = 'Exit game action';

export const TRIVIA_SUBMITTED_CATEGORY = 'trivia_submitted';
export const TRIVIA_ACTION = 'Trivia question answered';

export const POLL_SUBMITTED_CATEGORY = 'poll_submitted';
export const POLL_ACTION = 'Participated in a poll';

export const DJ_SUBMITTED_CATEGORY = 'dj_submitted';
export const DJ_ACTION = 'Participated in a DJ Request';

export const GAME_TIME_ENGAGEMENT = 'game_time_engagement';
export const GAME_TIME_BACKGROUND = 'game_time_background';

export const SUBMIT_CHAT_MESSAGE = 'submit_chat_message';
export const ASSET_CACHE_CATEGORY = 'asset_cache';
export const GAME_MEDIA_SUCCESS_ACTION = 'Cache game media success';
export const BOOTSTRAP_MEDIA_SUCCESS_ACTION = 'Cache bootstrap media success';
export const GAME_MEDIA_FAILURE_ACTION = 'Cache game media failure';
export const BOOTSTRAP_MEDIA_FAILURE_ACTION = 'Cache bootstrap media failure';

export const GAME_TIME_SHARE = 'game_time_share';
export const WATCH_PARTY_SHARE = 'watch_party_share';

export const AUTO_CALIBRATION = 'auto_calibration';
export const MANUAL_CALIBRATION = 'manual_calibration';
export const RESYNC_CALIBRATION = 'resync_calibration';
export const RESYNC_CALIBRATION_RESYNC = 'resync';
export const RESYNC_CALIBRATION_DISMISS = 'dismiss';

export const SCREEN_VIEW = 'screen_view';
export const PAGE_VIEW = 'page_view';

export const TOGGLE_RADIO = 'toggle_radio';
export const NAVIGATE_FROM_MENU = 'navigate_from_menu';
export const NAVIGATE_VIDEO_4UP_FRAME = 'navigate_video_4up_frame';
export const NAVIGATE_VIDEO_MAIN_FEED = 'navigate_video_main_feed';
export const USER_REGISTRATION = 'user_registration';
export const PRELOGIN_ERROR = 'pre_login_error';
export const IN_GAME_IN_STADIUM = 'in_game_in_stadium';
export const IN_GAME_OUTSIDE_STADIUM = 'in_game_outside_stadium';
