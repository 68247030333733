import styled from '@emotion/styled';
import {BodyText} from '../typography/BodyText';
import {curiousBlue, white} from '../../theme/vars';
import { Button } from '../buttons/Button';
import CloseIcon from '../../assets/icons/circle-x.svg';
import SVGIconButton from '../buttons/SVGIconButton';
import useToast from '../../hooks/useToast';

const ToastContainer = styled.div`
  background-color: ${curiousBlue};
  color: ${white};
  display: flex;
  justify-content: space-between;
`;

const CloseButtonContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  padding: 0.5rem;
  align-items: flex-start;
`;

const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  align-items: center;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  padding: 0.8rem 0.5rem;
`;

export type InteractionToastMessageProps = {
  message: string;
  hasCta?: boolean;
  ctaLabel?: string;
  onClickCta?: () => void;
  onDismiss?: () => void;
};

const InteractionToastMessage = ({message, hasCta, ctaLabel, onClickCta, onDismiss}: InteractionToastMessageProps) => {
  const toast = useToast();
  // Dismisses all toasts; may need more complex logic in the fututre to dismiss single toasts depending on user experience
  const dismissToast = () => {
    if (onDismiss) onDismiss();
    toast.dismissAllToasts();
  }

  return (
    <ToastContainer>
      <CloseButtonContainer>
        <SVGIconButton icon={<img src={CloseIcon} alt='close icon' width={18} height={18} />} onClick={dismissToast} />
      </CloseButtonContainer>
      <MessageContainer>
        <BodyText size='0.9rem'>
          {message}
        </BodyText>
      </MessageContainer>
      {hasCta && (
        <ActionButtonContainer>
          <Button className='interactionToastCtaButton' onClick={onClickCta}>{ctaLabel}</Button>
        </ActionButtonContainer>
      )}
    </ToastContainer>
  );
};

export default InteractionToastMessage;
