import {useState} from 'react';
import {useDispatch} from 'react-redux';

import InteractionContainer from '../../components/interactions/InteractionContainer';
import {resetInteraction} from '../../redux/modules/interaction';
import {setStep} from '../../redux/modules/dj';
import useDjSetup from '../../hooks/useDjSetup';
import {AnyAction} from '@reduxjs/toolkit';
import { djStep } from '../../constants/Dj.constants';
import { handleDjVoteCompletion, handleDjVoteDetails } from '../../redux/modules/dj';
import DjCountdownVote from '../../components/dj/DjCountdownVote';
import DjLoadingResults from '../../components/dj/DjLoadingResults';
import DjVoteResults from '../../components/dj/DjVoteResults';

type Props = {
  interaction: any;
  userId: string;
  gameId: string;
  djRequest: any;
  joinedTeam: {
    color: string;
  };
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
};

const Dj = ({
  interaction,
  userId,
  gameId,
  djRequest,
  joinedTeam,
  isWatchPartyConnected = false,
  isGuestUser,
}: Props) => {
  const dispatch = useDispatch();

  const {step} = djRequest;
  const {timeStarted} = step;

  const {interactionId, metadata: interactionMetaData} = interaction;
  const [songVote, setSongVote] = useState<string | null>(null);
  const {color: backgroundColor} = joinedTeam;

  const setCurrentStep = (step: any) => {
    dispatch(setStep({step, interactionId}));
  };

  useDjSetup({
    currentStep: step,
    setStep: setCurrentStep,
    gameId,
    interactionId,
  })

  const handleDjCountdownVote = (nextStep: any, songVote: any) => {
    setSongVote(songVote);
    setCurrentStep(nextStep);
  };

  const startDjSubmit = (body: any) =>
    dispatch(handleDjVoteDetails({body}) as unknown as AnyAction);

  const startClearInteractionType = () =>
    dispatch(resetInteraction() as unknown as AnyAction);

  const startDjCompletion = (interactionId: string) =>
    dispatch(handleDjVoteCompletion({interactionId}) as unknown as AnyAction);

  // switch to render
  const renderStateSwitch = (renderStep: {
    current: any;
    timeStarted: number;
  }) => {
    switch (renderStep?.current) {
      case djStep.COUNTDOWN_VOTE:
        console.log('showing countdown vote');
        console.dir(interaction);
        return (
          <DjCountdownVote
            interaction={interaction}
            setStep={handleDjCountdownVote}
            handleSubmit={startDjSubmit}
            timeStarted={timeStarted}
            userId={userId}
            isWatchPartyConnected={isWatchPartyConnected}
            backgroundColor={backgroundColor}
            isGuestUser={isGuestUser}
          />
        );
      case djStep.LOADING_VOTE_RESULTS:
        return (
          <DjLoadingResults
            interaction={interaction}
            setStep={setCurrentStep}
            songVote={songVote}
            isWatchPartyConnected={isWatchPartyConnected}
            clearInteractionType={startClearInteractionType}
            backgroundColor={backgroundColor}
          />
        );
      case djStep.RESULTS:
        return (
          <DjVoteResults
            interaction={interaction}
            clearInteractionType={startClearInteractionType}
            onDjCompletion={startDjCompletion}
            isWatchPartyConnected={isWatchPartyConnected}
            backgroundColor={backgroundColor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <InteractionContainer
      backgroundColor={backgroundColor}
      isWatchPartyConnected={false}
      content={
        interactionMetaData &&
        interactionMetaData.songs &&
        renderStateSwitch(step)
      }
    />
  );
};

export default Dj;
