import { useCallback } from 'react';
import { toast, TypeOptions, ToastOptions } from 'react-toastify';
import StatusToastMessage from '../components/toast/StatusToastMessage';
import PointsToastMessage from '../components/toast/PointsToastMessage';
import InteractionToastMessage, { InteractionToastMessageProps } from '../components/toast/InteractionToastMessage';
import { ToastContainerIds } from '../constants/Global.constants';

// Since we use custom toast components, this makes it easier to fire a toast without needing to import
// components & set the correct type. It also sets the global container ID, which can be overridden.
// The toast lib's 'type' field should always be 'default'.
const useToast = () => {
  const statusToast = useCallback(
    (message: string, status: TypeOptions, options?: ToastOptions) => {
      const _options: ToastOptions = {
        ...(options || {}),
        type: 'default',
        containerId: options?.containerId || ToastContainerIds.Global
      };

      toast(<StatusToastMessage status={status} statusText={message} />, _options);
    },
    [],
  );

  const pointsToast = useCallback(
    (payload: any, options?: ToastOptions) => {
      const _options: ToastOptions = {
        ...(options || {}),
        type: 'default',
        containerId: options?.containerId || ToastContainerIds.Global
      };

      toast(<PointsToastMessage pointsEarned={payload} />, _options);
    },
    [],
  );

  const interactionToast = useCallback(
    (payload: InteractionToastMessageProps, options?: ToastOptions) => {
      const _options: ToastOptions = {
        ...(options || {}),
        type: 'default',
        containerId: options?.containerId || ToastContainerIds.Global,
        closeOnClick: false,
        
      };

      toast(<InteractionToastMessage message={payload.message} hasCta={payload.hasCta} onDismiss={payload.onDismiss} ctaLabel={payload.ctaLabel} onClickCta={payload.onClickCta} />, _options);
    },
    [],
  );

  const dismissAllToasts = useCallback(
    () => toast.dismiss(),
    [],
  );

  return {
    statusToast,
    pointsToast,
    interactionToast,
    dismissAllToasts,
  }
};

export default useToast;
