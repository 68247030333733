import {createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../../store';

type InteractionNotification = {
  interactionType: string | null;
  title: string | null;
  body: string | null;
  gameId: string | null;
  teamId: string | null;
};

const initialState: InteractionNotification = {
  interactionType: null,
  title: null,
  body: null,
  gameId: null,
  teamId: null,
};

const notificationSlice = createSlice({
  name: 'interaction_notification',
  initialState,
  reducers: {
    setNotification(state, action) {
      state.interactionType = action.payload.interactionType;
      state.title = action.payload.title;
      state.body = action.payload.body;
      state.gameId = action.payload.gameId;
      state.teamId = action.payload.teamId;
    },
    clearNotification(state) {
      state.interactionType = initialState.interactionType;
      state.title = initialState.title;
      state.body = initialState.body;
      state.gameId = initialState.gameId;
      state.teamId = initialState.teamId;
    },
  },
});

export const { setNotification, clearNotification} =
  notificationSlice.actions;

export const triggerNotification =
  (notification: InteractionNotification): AppThunk =>
  async (dispatch, getState) => {
    console.log('triggerNotification');
    console.dir(notification);
    dispatch(setNotification(notification));
  };

export const resetNotification = (): AppThunk => async dispatch => {
  dispatch(clearNotification());
};
export default notificationSlice.reducer;
