import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {triviaStep} from '../constants/Trivia.constants';
// import {GET_READY_STEP_TIMEOUT} from '../constants/Trivia.constants';
import {
  selectGameStatusById as selectTriviaGameStatusById,
  updateGameStatus as updateTriviaGameStatus,
} from '../redux/modules/trivia';
import {
  selectGameStatusById as selectPollGameStatusById,
  updateGameStatus as updatePollGameStatus,
} from '../redux/modules/poll';
// import {showInteraction} from '../redux/actions';
// import {clearPlayer} from '../redux/modules/videoPlayer';
import {RootState} from '../redux/rootReducer';
import {showInteraction} from '../redux/actions';
// import {AnyAction} from '@reduxjs/toolkit';

// type UpdateLastStartedTime = (time: number) => void;

// const getStep = (
//   triviaTimeStampValue: string,
//   updateLastStartedTime: UpdateLastStartedTime,
//   startVideo: () => void,
// ) => {
//   let step = triviaStep.GET_READY;
//   const currentTimestamp = new Date().getTime();
//   const triviaTimeStamp = new Date(triviaTimeStampValue).getTime();

//   if (triviaTimeStampValue) {
//     if (currentTimestamp - triviaTimeStamp <= GET_READY_STEP_TIMEOUT) {
//       step = triviaStep.COUNTDOWN_QUESTION;
//     } else {
//       updateLastStartedTime(currentTimestamp);
//     }
//   } else {
//     updateLastStartedTime(currentTimestamp);
//   }

//   if (step === triviaStep.GET_READY) {
//     startVideo();
//   }

//   return step;
// };

interface Props {
  currentStep: any;
  setStep: (step: triviaStep) => void;
  gameId: string;
  interactionId: number;
  type: string;
}

export default function useTriviaPollSetup({
  currentStep,
  setStep,
  gameId,
  interactionId,
  type,
}: Props) {
  const dispatch = useDispatch();
  const [selectGameStatusById, updateGameStatus] =
    type === 'poll'
      ? [selectPollGameStatusById, updatePollGameStatus]
      : [selectTriviaGameStatusById, updateTriviaGameStatus];

  const gameStatus = useSelector((state: RootState) =>
    selectGameStatusById(state, gameId),
  );
  const {watchPartyConnected} = useSelector((state: RootState) => state.game);

  useEffect(() => {
    console.log(`useTriviaPollSetup currentStep.current=${currentStep?.current}`);
    if (!currentStep.current) {
      // const step = watchPartyConnected
      //   ? triviaStep.COUNTDOWN_QUESTION
      //   : getStep(
      //       gameStatus?.lastStartedTime,
      //       timestamp =>
      //         dispatch(
      //           updateGameStatus({gameId: gameId, lastStartedTime: timestamp}),
      //         ),
      //       () =>
      //         dispatch(
      //           setStep(triviaStep.COUNTDOWN_QUESTION) as unknown as AnyAction,
      //         ),
      //       // dispatch(
      //       //   playVideo({
      //       //     name: `${type}_video`,
      //       //     onEnd: () => {
      //       //       setStep(triviaStep.COUNTDOWN_QUESTION);
      //       //       dispatch(clearPlayer());
      //       //       dispatch(showInteraction());
      //       //     },
      //       //   }),
      //       // ),
      //     );

      setStep(triviaStep.COUNTDOWN_QUESTION);
      dispatch(showInteraction());
    }
  }, [
    currentStep,
    dispatch,
    gameId,
    gameStatus?.lastStartedTime,
    setStep,
    updateGameStatus,
    watchPartyConnected,
  ]);

  useEffect(() => {
    console.log(`useTriviaPollSetup currentStep.interactionId=${currentStep?.interactionId}`);
    if (
      currentStep.interactionId &&
      currentStep.interactionId !== interactionId
    ) {
      // if (currentStep.current === triviaStep.GET_READY) {
      //   dispatch(clearPlayer() as AnyAction);
      // }
      setStep(triviaStep.COUNTDOWN_QUESTION);
    }
  }, [interactionId, currentStep, setStep]);
}
