import {createSlice} from '@reduxjs/toolkit';
import {Interaction} from '../interaction';

type InteractionHistoryEntry = {
  interaction: Interaction;
  timestamp: number;
  completed: boolean;
}

type InteractionHistory = {
  visible: boolean;
  history: InteractionHistoryEntry[];
};

const initialHistoryState: InteractionHistory = {
  visible: false,
  history: []
}

const interactionHistorySlice = createSlice({
  name: 'interactionHistory',
  initialState: initialHistoryState,
  reducers: {
    showHistory(state) {
      state.visible = true;
    },
    hideHistory(state) {
      state.visible = false;
    },
    addInteraction(state, action) {
      const historyItem = {
        completed: false,
        interaction: {
          ...action.payload,
          realtime: false
        },
        timestamp: new Date().getTime()
      };
      console.log('adding history item');
      console.dir(historyItem);
      state.history.push(historyItem);
      console.log('interactionHistory addInteraction');
      console.dir(state.history);
    },
    markInteractionComplete(state, action) {
      const interactionId = action.payload.interactionId;
      console.log(`marking interaction complete ${interactionId}`);
      const entry = state.history.find(e => e.interaction.interactionId === interactionId);
      if (entry) {
        entry.completed = true;
      }
      console.log('history after complete');
      console.dir(state.history);
    },
    clearHistory(state) {
      state.history = [];
    }
  }
});

export const { showHistory, hideHistory, addInteraction, markInteractionComplete, clearHistory } =
  interactionHistorySlice.actions;

export default interactionHistorySlice.reducer;
