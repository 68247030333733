import {useState} from 'react';
import styled from '@emotion/styled';

// styles
import {black, curiousBlue, transparent} from '../../theme/vars';

// components
import DjSongButton from './DjSongButton';
import DjRequestCountdown from './DjRequestCountdown';
import DjHeader from './DjHeader';

// hooks
import useJoinedTeam from '../../hooks/useJoinedTeam';
import useJoinedGame from '../../hooks/useJoinedGame';

import {trackEventData} from '../../utils/analytics';
import {
  DJ_SUBMITTED_CATEGORY,
  DJ_ACTION,
} from '../../constants/analytics.constants';
import {djStep} from '../../constants/Dj.constants';
import Title from './Title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  margin: 1rem;

  .questionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: ${black};
  }

  .buttonContainer {
    display: flex;
    flex: 1;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    padding-bottom: 1rem;
    width: 100%;
  }
`;

type Props = {
  interaction: any;
  handleSubmit: (body: any, songVote: any) => void;
  userId: string;
  setStep: (num: number, songVote: any) => void;
  backgroundColor: string;
  isWatchPartyConnected?: boolean;
  isGuestUser: boolean;
  timeStarted?: number;
};

const DjCountdownVote = ({
  interaction,
  handleSubmit,
  userId,
  setStep,
  backgroundColor,
  isWatchPartyConnected = false,
  isGuestUser,
  timeStarted,
}: Props) => {
  const {metadata: interactionMetaData} = interaction;

  const [songVote, setSongVote] = useState<any>(null);

  const joinedTeam = useJoinedTeam();
  const joinedGame = useJoinedGame();

  const submitVote = (songId: any) => {
    console.log(`submitVote realtime=${interaction.realtime}`);
    const body = {
      interactionId: interaction ? interaction.interactionId : null,
      realtime: interaction.realtime,
      djRequestId: interactionMetaData ? interactionMetaData.djRequestId : null,
      songId: songId,
      userId: userId,
      isGuestUser,
    };

    trackEventData(DJ_SUBMITTED_CATEGORY, {
      action: DJ_ACTION,
      interaction_id: body.interactionId,
      dj_request_id: body.djRequestId,
      song_id: songId,
      user_id: userId,
      game_id: joinedGame?.id,
      team_id: joinedTeam?.id,
    });
    handleSubmit(body, songVote);
  };

  const getCountDown = () => {
    return (
      <DjRequestCountdown
        timeStarted={timeStarted}
        countDownComplete={() =>
          setStep(djStep.LOADING_VOTE_RESULTS, songVote)
        }
      />
    );
  };

  return (
    <Container>
      {interactionMetaData && (
        <>
          <DjHeader
            header={'BE THE DJ'}
            backgroundColor={backgroundColor}
            joinedTeam={joinedTeam}
            headerFeature={getCountDown()}
            isWatchPartyConnected={isWatchPartyConnected}
          />
          <div className="questionWrapper">
            <Title text="What should we play next?" />
            <div className="buttonContainer">
              {interactionMetaData.songs.map((song: any) => {
                return (
                  <div key={song.id} className="buttonWrapper">
                    <DjSongButton
                      disabled={false}
                      song={song}
                      onPressSongButton={id => {
                        setSongVote(id);
                        submitVote(id);
                      }}
                      percent={100}
                      shouldAnimateWidth={false}
                      backgroundColor={
                        songVote === song.id ? curiousBlue : transparent
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default DjCountdownVote;
