import {useState, useEffect, useCallback} from 'react';
import {curiousBlue, lightGrey, white} from '../../theme/vars';
import {CircularProgressbar} from 'vovanmix-react-circular-progressbar';
import 'vovanmix-react-circular-progressbar/dist/styles.css';
import styled from '@emotion/styled';
import { TRIVIAL_POLL_QUESTION_DELAY_MS } from '../../constants/Global.constants';

function toFixedNumber(num: number, digits: number, base: number = 10) {
  const pow = Math.pow(base, digits);
  return Math.round(num * pow) / pow;
}

const Container = styled.div`
  .CircularProgressbar-path {
    stroke: ${curiousBlue};
    stroke-linecap: butt;
  }
  .CircularProgressbar-trail {
    stroke: ${lightGrey};
  }
  .CircularProgressbar-text {
    fill: ${white};
    font-family: 'Industry-UltraItalic';
    font-size: 2rem;
    line-height: 1.2;
  }
`;

type Props = {
  isDone?: boolean;
  countDownComplete?: () => void;
  timeStarted?: number;
};

const getCountdownMS = (timeStarted?: number): number => {
  if (timeStarted) {
    const now = Date.now();
    const timeDiffeerence = now - timeStarted;
    return TRIVIAL_POLL_QUESTION_DELAY_MS - timeDiffeerence;
  }
  return TRIVIAL_POLL_QUESTION_DELAY_MS;

}

const DjRequestCountdown = ({
  isDone = false,
  countDownComplete,
  timeStarted,
}: Props) => {
  const [countdownMS, setCountdownMS] = useState(isDone ? 0 : getCountdownMS(timeStarted));

  const nextStep = useCallback(() => {
    if (countDownComplete) countDownComplete();
  }, [countDownComplete]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;

    if (countdownMS > 0) {
      interval = setInterval(() => {
        setCountdownMS(countdownMS - 1000);
      }, 1000);
    } else {
      nextStep();
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [countdownMS, isDone, nextStep]);

  return (
    <Container>
      <CircularProgressbar
        value={countdownMS}
        minValue={100}
        maxValue={TRIVIAL_POLL_QUESTION_DELAY_MS}
        strokeWidth={10}
        text={`${toFixedNumber(countdownMS / 1000, 0)}`}
      />
    </Container>
  );
};

export default DjRequestCountdown;
