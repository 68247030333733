// styles
import {grey, white} from '../../theme/vars';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;

  .buttonOutline {
    border: 2px solid white;
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }

  .disabledButtonOutline {
    border-color: ${grey};
  }
`;

const EntryText = styled.div`
  color: ${white};
  display: flex;
  align-items: center;
  font-family: 'Roboto-Bold';
  font-size: 14px;
  width: 100%;
  left: 10px;
  line-height: 15px;
  position: absolute;
  text-transform: uppercase;

  &.disabledText {
    color: ${grey};
  }
  .song-list {
    width: 75%;
  }

  .sent-time {
    width: 25%;
  }
`;

type Props = {
  disabled: boolean;
  hasDisabledStyle?: boolean;
  backgroundColor: string;
  historyEntry: any;
  onPressButton?: (id: number) => void;
};

const DjHistoryItem = ({
  disabled,
  hasDisabledStyle = false,
  backgroundColor,
  historyEntry,
  onPressButton,
}: Props) => {

  const songList = historyEntry.interaction.metadata.entries.map((e: { team_dj_song_id: any; }) => {
    const song = historyEntry.interaction.metadata.songs.find((s: { id: any; }) => e.team_dj_song_id === s.id);
    return {
      ...e,
      song_name: song ? song.song_name : "N/A",
      artist_name: song ? song.artist_name : "N/A"
    }
  }).sort((a: { sort_order: number; }, b: { sort_order: number; }) => a.sort_order - b.sort_order);

  const entryTimestamp = (timestamp: number) => {
    const d = new Date();
    d.setTime(timestamp);
    return d.toLocaleTimeString();
  }

  return (
    <Container
      onClick={() => {
        if (!disabled) {
          onPressButton?.(historyEntry);
        }
      }}>
      <EntryText
        className={`${(hasDisabledStyle && 'disabledText') || ''}`}
      >
        <ul className='song-list'>
          {songList.map((s: any) => (
            <li key={s.id}>{s.song_name}</li>
          ))}
        </ul>
        <div className='sent-time'>Sent: {entryTimestamp(historyEntry.timestamp)}</div>
      </EntryText>
    </Container>
  );
};

export default DjHistoryItem;
