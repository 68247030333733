import {RootState} from '../../rootReducer';
import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {request, INTERACTIONS_ROUTES} from '../../../api';
import {AppThunk} from '../../store';
import { markInteractionComplete } from '../interaction-history';

type SliceState = {
  submittingDjVote: boolean;
  success: any | null;
  error: any | null;
  step: {
    interactionId: string | null;
    timeStarted: number | null;
    current?: any;
  };
};

const initialValueState: SliceState = {
  submittingDjVote: false,
  success: null,
  error: null,
  step: {
    interactionId: null,
    timeStarted: null,
    current: null,
  },
};

const resultsAdapter = createEntityAdapter({
  selectId: (result: any) => result.interactionId,
});

const gameStatusAdapter = createEntityAdapter({
  selectId: (gameStatus: any) => gameStatus.gameId,
});

const initialState = {
  ...initialValueState,
  results: resultsAdapter.getInitialState(),
};

const djVoteSlice = createSlice({
  name: 'dj',
  initialState: gameStatusAdapter.getInitialState(initialState),
  reducers: {
    updateGameStatus: gameStatusAdapter.upsertOne,
    setStep(state, action) {
      state.step = {
        timeStarted: Date.now(),
        current: action.payload.step,
        interactionId: action.payload.interactionId,
      };
    },
    submitDjVoteRequest(state) {
      state.submittingDjVote = true;
      state.error = null;
    },
    submitDjVoteSuccess(state) {
      state.submittingDjVote = false;
      state.success = true;
    },
    submitDjVoteError(state, action) {
      state.submittingDjVote = false;
      state.error = action.payload.error;
    },
    clearDjVoteStatus(state) {
      state.submittingDjVote = false;
      state.success = null;
      state.error = null;
    },
    djVoteResults(state, action) {
      resultsAdapter.addOne(state.results, action.payload);
    },
    removeDjVoteResults(state, action) {
      resultsAdapter.removeOne(state.results, action.payload);
    },
    clearDjVote(state) {
      return {...state, ...initialState};
    },
  },
});

export const {
  updateGameStatus,
  setStep,
  submitDjVoteRequest,
  submitDjVoteSuccess,
  submitDjVoteError,
  clearDjVoteStatus,
  djVoteResults,
  removeDjVoteResults,
  clearDjVote,
} = djVoteSlice.actions;

const apiSubmitDjVote = async (body: any) => {
  return request({
    route: INTERACTIONS_ROUTES.SUBMIT_DJ,
    body: body,
  });
};

export const handleDjVoteDetails =
  ({body}: {body: any}): AppThunk =>
  async dispatch => {
    dispatch(submitDjVoteRequest());
    try {
      const {err} = await apiSubmitDjVote(body);
      if (err) {
        return dispatch(submitDjVoteError((err as Error).message));
      }
      dispatch(submitDjVoteSuccess());
      dispatch(markInteractionComplete({ interactionId: body.interactionId}));
    } catch (err) {
      dispatch(submitDjVoteError((err as Error).message));
    }
  };

export const handleDjVoteCompletion =
  ({interactionId}: {interactionId: any}): AppThunk =>
  async dispatch => {
    dispatch(removeDjVoteResults(interactionId));
  };

export const {selectById: selectGameStatusById} =
  gameStatusAdapter.getSelectors((state: RootState) => state.dj);

export const {selectById: selectResultById} = resultsAdapter.getSelectors(
  (state: RootState) => state.dj.results,
);

export default djVoteSlice.reducer;
