//asset
import styled from '@emotion/styled';
import {black} from '../../theme/vars';

// type
import {AssetType} from '../../types/assetType';
import {HeaderLarge} from '../typography/HeaderLarge';
import {clampBuilder} from '../../utils/style';
import ThreeSectionTitleHeader from '../headers/ThreeSectionTitleHeader';
import {getTeamImage} from '../../utils/media';
import ButtonIcon from '../buttons/ButtonIcon';
import whiteX from '../../assets/icons/white-x.svg';

const ClampedHeaderLarge = styled(HeaderLarge)`
  font-size: ${clampBuilder(1, 2)};
  line-height: ${clampBuilder(1, 2)};
  align-self: center;
  padding-bottom: 1rem;
  width: 30vw;
  white-space: nowrap;
`;

type ContainerProps = {
  background: string;
  isWatchPartyConnected?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  margin-bottom: 0;

  .wordMarkWrapper {
    align-self: center;
    align-items: center;
    max-width: 15rem;
    margin: 0;
    padding: 0;
  }

  .sponsorWrapper {
    clip-path: polygon(5vw 0, 150% 0, 150% 150%, 0 150%);
    background-color: ${black};
    display: flex;
    align-items: center;
    align-self: center;
    padding: 1rem;
    
  }
  
  h2 {
    padding-bottom: 0;
  }

  .triviaPollBar {
    background-color: ${black};
    height: 0.75rem;
    margin: -1px 0;
  }

  .headerFeature {
    position: absolute;
    bottom: -2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .headerFeatureContainer {
    display: flex;
    align-items: center;
    background-color: ${black};
    height: 75px;
    justify-content: center;
    width: 75px;
    border-radius: 50px;
    border: 5px ${black} solid;
  }
`;

const SponsorImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

type Props = {
  header: string;
  backgroundColor: string;
  joinedTeam: any;
  onClose: () => void;
};

const DjHistoryHeader = ({
  header,
  backgroundColor,
  joinedTeam,
  onClose,
}: Props) => {
  const sponsoredImage = getTeamImage({
    type: AssetType.TriviaSponsorLogo, // TODO: Does DJ need sponsored image
    teamId: joinedTeam.id,
  });

  return (
    <Container
      background={backgroundColor}>
      <>
        <ThreeSectionTitleHeader
          TitleComponent={ClampedHeaderLarge}
          backgroundColor="unset"
          padding="0"
          maxWidth="unset"
          height={'5rem'}
          alignItems="stretch"
          leftContainer={
            <div className="wordMarkWrapper">
              <ButtonIcon
                icon={whiteX}
                alt="Close"
                onClick={() => onClose()}
              />
            </div>
          }
          rightContainer={
            sponsoredImage && (
              <div className="sponsorWrapper">
                <SponsorImage
                  alt={sponsoredImage.title || 'sponsored'}
                  src={sponsoredImage.mediaUrl}
                />
              </div>
            )
          }>
          {header}
        </ThreeSectionTitleHeader>

        {/* <div className="triviaPollBar" /> */}
      </>
    </Container>
  );
};

export default DjHistoryHeader;
