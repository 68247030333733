import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {djStep} from '../constants/Dj.constants';

import {
  selectGameStatusById
} from '../redux/modules/dj';

import {RootState} from '../redux/rootReducer';
import {showInteraction} from '../redux/actions';

interface Props {
  currentStep: any;
  setStep: (step: djStep) => void;
  gameId: string;
  interactionId: number;
}

export default function useDjSetup({
  currentStep,
  setStep,
  gameId,
  interactionId,
}: Props) {
  const dispatch = useDispatch();
  
  const gameStatus = useSelector((state: RootState) =>
    selectGameStatusById(state, gameId),
  );
  const {watchPartyConnected} = useSelector((state: RootState) => state.game);

  useEffect(() => {
    if (!currentStep.current) {
      setStep(djStep.COUNTDOWN_VOTE);
      dispatch(showInteraction());
    }
  }, [
    currentStep,
    dispatch,
    gameId,
    gameStatus?.lastStartedTime,
    setStep,
    watchPartyConnected,
  ]);

  useEffect(() => {
    if (
      currentStep.interactionId &&
      currentStep.interactionId !== interactionId
    ) {
      setStep(djStep.COUNTDOWN_VOTE);
    }
  }, [interactionId, currentStep, setStep]);
}
