import useJoinedTeam from '../../hooks/useJoinedTeam';
import InteractionContainer from '../../components/interactions/InteractionContainer';
import DjHistoryList from '../../components/dj/DjHistoryList';

const DjHistory = () => {
  const joinedTeam = useJoinedTeam();

  const {color: backgroundColor} = joinedTeam;

  return (
    <InteractionContainer
      backgroundColor={backgroundColor}
      isWatchPartyConnected={false}
      content={(
        <DjHistoryList />
      )}
    />
  );
};

export default DjHistory;
