export const header = 'BE THE DJ';
export const loadingText = 'Tabulating results';
export const results = 'Results';
export enum djStep {
  GET_READY = 1,
  COUNTDOWN_VOTE = 2,
  TRANSITION_TO_RESULTS = 3,
  LOADING_VOTE_RESULTS = 4,
  RESULTS = 5,
}
export const GET_READY_STEP_TIMEOUT = 300000;
