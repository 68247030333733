import React from 'react';
import {useSelector} from 'react-redux';

import InteractionPlaceholder from '../../components/interactions/InteractionPlaceholder';
import ImageInteraction from './ImageInteraction';

import useJoinedIds from '../../hooks/useJoinedIds';
import useJoinedTeam from '../../hooks/useJoinedTeam';

import {RootState} from '../../redux/rootReducer';
import {POLL, TRIVIA, DJ, MEDIA} from '../../constants/Pusher.constants';
import Trivia from '../trivia/Trivia';
import Poll from '../poll/Poll';
import Dj from '../dj/Dj';

const Interaction = () => {
  const {gameId, userId} = useJoinedIds();
  const joinedTeam = useJoinedTeam();

  const trivia = useSelector((state: RootState) => state.trivia);
  const poll = useSelector((state: RootState) => state.poll);
  const dj = useSelector((state: RootState) => state.dj);
  const interaction = useSelector((state: RootState) => state.interaction);
  const isGuestUser = useSelector((state: RootState) => state.user?.userData?.guestUser ?? true);

  if (interaction.visible && userId && gameId) {
    console.log('checking interaction');
    console.dir(interaction);
    switch (interaction.interactionType) {
      case POLL:
        return (
          <Poll
            interaction={interaction}
            userId={userId}
            gameId={gameId}
            poll={poll}
            joinedTeam={joinedTeam}
            isWatchPartyConnected={false}
            isGuestUser={isGuestUser}
          />
        );
      case TRIVIA:
        return (
          <Trivia
            interaction={interaction}
            userId={userId}
            gameId={gameId}
            trivia={trivia}
            joinedTeam={joinedTeam}
            isWatchPartyConnected={false}
            isGuestUser={isGuestUser}
          />
        );
      case DJ:
        return (
          <Dj
            interaction={interaction}
            userId={userId}
            gameId={gameId}
            djRequest={dj}
            joinedTeam={joinedTeam}
            isWatchPartyConnected={false}
            isGuestUser={isGuestUser}
          />
        );
      case MEDIA:
        if (interaction.mediaType === 'image' && interaction.mediaPath) {
          return (
            <ImageInteraction
              mediaPath={interaction.mediaPath}
              joinedTeam={joinedTeam}
            />
          );
        }
        break;
      default:
        return <InteractionPlaceholder />;
    }
  }
  return null;
};

export default Interaction;
