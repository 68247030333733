import React from 'react';
import {useSelector} from 'react-redux';

import useJoinedIds from '../../hooks/useJoinedIds';

import {RootState} from '../../redux/rootReducer';
import DjHistory from '../dj/DjHistory';


const InteractionHistory = () => {
  const {gameId, userId} = useJoinedIds();

  const history = useSelector((state: RootState) => state.interactionHistory);

  if (history.visible && userId && gameId) {
    return (
      <DjHistory />
    );
  }
  return null;
};

export default InteractionHistory;
